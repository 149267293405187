.notes-content {
  a:before {
    display: block;
    content: "";
    height: 50px;
    margin: -50px 0 0;
  }

  h1, h2, h3, h4, h5, h6 {
    margin-top: 1.5em;
    font-weight: 400;
    color: #593196;
    &:hover {
      color: rgba(89, 49, 150, 0.9);
    }
  }

  h3 {
    font-size:1.45em;
  }

  h1, h2 {
    &:first-child {
      margin-top:-75px;
    }
  }
}

.notes-wrapper {
  footer {
    padding-left: 0;
    margin-left: 0;
  }
}
.big-line {
  height: 1.2px;
  float: left;
  width: 45%;
}
#notes-menu {
  ol {
    list-style: upper-roman;
    font-weight: 800;

    ol {
      list-style: decimal;
      font-weight: 500;
      padding-left: 0.5em;

      ol {
        list-style: lower-alpha;
        font-weight: normal;
      }
    }
  }
}

.notes-search-no-results {
  display: none;
}

.jumbotron h1 {
    word-break: break-word;
}